export default defineNuxtRouteMiddleware((to, from) => {
  // const auth = useCookie<boolean>('auth')
  // if (to.path === '/') {
  //   if (auth.value) {
  //     return { path: 'comparison' }
  //   }
  // } else if (!auth.value || auth === undefined) {
  //   return { path: '/' }
  // }
})
